import React from 'react';
import ReactDOM from 'react-dom';
import HttpsRedirect from 'react-https-redirect';
import Nav from './components/Nav';
import UrlCreator from './components/UrlCreator';
import { unregister } from './registerServiceWorker';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Sentry from "@sentry/react";

import { Route, BrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

const REACT_APP_WHITE_LABEL = process.env.REACT_APP_WHITE_LABEL === 'true'

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      dsn: "https://e5559adb41f7bef47f4c95e8f782f437@o4505878717267968.ingest.sentry.io/4505952135544832",
      integrations: [new Sentry.BrowserTracing()],
    
      tracesSampleRate: 1.0,
    });
}

const Root = () => {
  return (
    <div>
      <HttpsRedirect>
        <BrowserRouter>
          <div>
            {!REACT_APP_WHITE_LABEL && <Nav />}
              <QueryParamProvider ReactRouterRoute={Route}>
                 <UrlCreator/>
              </QueryParamProvider>
          </div>
        </BrowserRouter>
      </HttpsRedirect>
      <ToastContainer/>
    </div>
  )
}

ReactDOM.render(<Root />, document.getElementById('root'));
unregister();
