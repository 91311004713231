import React, {useEffect, useState, useMemo} from 'react';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import {useLocalStorage} from "@rehooks/local-storage";
import {toast} from 'react-toastify';
import {
    useQueryParams,
    StringParam,
    NumberParam,
    withDefault,
} from 'use-query-params';

import sites from './Sites';
import {
    getArticles,
    getWidgetConfig,
    getWidgetPath,
    isBlacklistedUrl,
    generatePixelValues
} from './Api';

import { ALLOWED_VERSIONS, SITE_WIDGET_FILTER, DEVICE_LIST, SOURCE_LIST } from './Constants';

let timeoutIsBlacklistedUrl;

const pixelValuesList = generatePixelValues();

const UrlCreator = () => {

    const [otherSource, setOtherSource] = useState('');
    const [article, setArticle] = useState({});
    const [freetext, setFreetext] = useState('');
    const [additionalParameters, setAdditionalParameters] = useState('');
    const [codeName, setCodeName] = useState(undefined);
    const [widgetId, setWidgetId] = useState(undefined);
    const [widgetPath, setWidgetPath] = useState(undefined);
    const [pixelValue, setPixelValue] = useState([]);
    const [url, setUrl] = useState(''); // url before validation
    const [verifiedUrl, setVerifiedUrl] = useState(''); // after check of black list
    const [tags, setTags] = useState([]); // after check of black list
    const [fullUrl, setFullUrl] = useState(''); // url with tags
    const [error, setError] = useState('');
    const [website, setWebsite] = useState('');
    const [articlesFullList, setArticlesFullList] = useState([]);
    const [widgetConfig, setWidgetConfig] = useState([]);
    const [disabledEpc, setDisabledEpc] = useState(false);
    const [loading, setLoading] = useState(true);
    
    const [prefix, setPrefix] = useLocalStorage('prefix', '');
    const [site, setSite] = useLocalStorage('site', '');
    const [country, setCountry] = useLocalStorage('country', '');
    const [device, setDevice] = useLocalStorage('device', DEVICE_LIST[0].value);
    const [source, setSource] = useLocalStorage('source', SOURCE_LIST[0].value);

    useEffect(() => {
        const fetchData = async () => {
            const [allArticles, widgetConfig]  = await Promise.all([getArticles(), getWidgetConfig()]);
            setArticlesFullList(allArticles);
            setLoading(false);
            setWidgetConfig(widgetConfig);
        };
        fetchData().catch(e => setError(e.message));
    }, []);

    const articles = useMemo(() => {
        if(!articlesFullList.length) return [];

        let versionsFilter = ALLOWED_VERSIONS;

        if(site.length === 3) {
            versionsFilter = versionsFilter.concat(sites.reduce((acc, item) => {
                if(item.code === site) acc.push(item.code);
                return acc;
            }, []));
        }

        if(sites.filter(item => item.code === site).length && SITE_WIDGET_FILTER[site]) {
            versionsFilter = versionsFilter.concat(SITE_WIDGET_FILTER[site]);
        }

        return articlesFullList.filter(el => {
            if (!el.name) {
                return false;
            }
            const version = el.name.substring(7, 10);
            return versionsFilter.includes(version);
        });
    }, [articlesFullList, site]);

    const articlesOptionsList = useMemo(() => {
        if(!articlesFullList.length) return [];
        
        let versionsFilter = ALLOWED_VERSIONS;
        
        return articlesFullList
            .filter(item => typeof item.name === 'string' && versionsFilter.some(str => item.name.toString().endsWith(str)))
            .map((item) => {
                return {
                    label: item.name.substring(0, 7),
                    value: item.name.substring(0, 7)
                };
        });
    }, [articlesFullList]);

    const nameToId = useMemo(() => {
        // key - widget id
        // value - "top" widget id, according to the widget configuration in BO
        const topIdsDict = {};
        Object.keys(widgetConfig).forEach(key => {
            widgetConfig[key].forEach(subId => {
                // If key is not present in widget config top level already
                if(!widgetConfig[subId]) {
                    topIdsDict[subId] = key;
                }
            });
        });

        const nameToId = {};
        articles.forEach(a => {
            const name = a.name.substring(0, 7);
            const existing = nameToId[name];
            if (existing && widgetConfig[existing]) {
                // This article has already been assigned to top id
            } else {
                nameToId[name] = topIdsDict[a.id] || a.id;
            }
        });
        return nameToId;
    }, [articles, widgetConfig]);

    const idToName = useMemo(() => {
        const idToName = {};
        articles.forEach(a => {
            idToName[a.id] = a.name.substring(0, 7);
        });

        return idToName;
    }, [articles]);

    const isNativeSource = (source) => ["taboola", "outbrain", "gemini", "revcontent"].includes(source);

    const getDeviceLetter = (device) => {
        const DEVICE_DICT = {
            desktop: 'd',
            mobile: 'm',
            tablet: 't',
            all: 'a',
        }
        return DEVICE_DICT[device] || '';
    }

    useEffect(() => {
        if (error) {
            setVerifiedUrl('');
            setUrl('');
        }
    }, [error]);

    // useEffect(() => {
    //     if (disabledEpc) {
    //         setPixelValue([])
    //     }
    // }, [disabledEpc]);

    useEffect(() => {
        if (verifiedUrl) {
            setError('');
        }
    }, [verifiedUrl]);

    // something like: ?x=123&q=foo&filters=a&filters=b&filters=c in the URL
    const [query] = useQueryParams({
        prefix: StringParam,
        site: StringParam,
        country: StringParam,
        device: withDefault(StringParam, device),
        article: StringParam,
        widgetid: StringParam,
        source: withDefault(StringParam, source),
        freetext: StringParam,
        pixel_id: StringParam,
        pixel_value: NumberParam,
    });

    useEffect(() => {
        if (query.source) {
            if (SOURCE_LIST.map(el => el.value).indexOf(query.source.toLowerCase()) !== -1) {
                setSource(query.source.toLowerCase());
            } else {
                setSource('other');
            }
        }
        query.prefix && setPrefix(query.prefix.toLowerCase());
        query.site && setSite(query.site.toLowerCase().toLowerCase());
        query.country && setCountry(query.country.toLowerCase());
        query.device && setDevice(query.device.toLowerCase());
        query.freetext && setFreetext(query.freetext.toLowerCase());
        query.pixel_value && setPixelValue(query.pixel_value);
        if (query.article) {
            setArticle(query.article);
        } else if (query.widgetid) {
            setArticle(query.widgetid);
        }
    }, [query]);

    useEffect(() => {
        if (source === 'other') {
            setOtherSource(query.source.toLowerCase());
        } else {
            if (query.source !== 'other') {
                setOtherSource(query.source.toLowerCase());
            } else {
                setOtherSource('');
            }
        }
        if (!isNativeSource(source)) {
            setAdditionalParameters('');
        }
    }, [source]);

    useEffect(() => {
        if (article) {
            const getWidgetId = (article) => (article.length > 8 && idToName[article]) ? article : nameToId[article];
            const newWidgetId = getWidgetId(article);
            const newCodeName = idToName[newWidgetId];
            setCodeName(newCodeName);
            setWidgetId(newWidgetId);
        }
    }, [article, idToName, nameToId]);

    useEffect( () => {
        if (widgetId) {
            getWidgetPath(widgetId).then(pathData => {
                setWidgetPath(pathData.path);
            }).catch(e => {
                setError(e.message)
            });
        } else {
            setWidgetPath(undefined);
        }
    }, [widgetId]);

    useEffect(() => {
        const newWebsite = sites.find(s => s.code === site);
        if (newWebsite) {
            setWebsite(newWebsite.name);
        } else {
            setWebsite(undefined);
        }
    }, [site]);


    useEffect(() => {
        if (!prefix || prefix === '') {
            setError('Please enter required prefix');
        } else if (!website || website === '') {
            setError('Please enter correct site');
        } else if (!country || country === '') {
            setError('Please enter Country code');
        } else if (!device) {
            setError('Please select device');
        } else if (source === 'other' && (!otherSource || otherSource === '')) {
            setError('Please enter trafic Source');
        } else if (!article || article === {} || article === [] || article === null) {
            setError('Please enter Article code');
        } else if (!widgetId || widgetId === '') {
            setError("Can't find article code");
        } else if (!codeName || codeName === '') {
            setError("Can't find the article code");
        }  else if (!source || source === '') {
            setError('Please select source');
        } else if (source === 'facebook' && !pixelValue.length && !disabledEpc) {
            setError('Please select EPC value or Link Clicks');
        }
        else {
            if (widgetPath) {
                setUrl(`https://${website}/${widgetPath}-${prefix}/`);
            }
        }
    }, [prefix, country, device, widgetId, widgetPath, codeName, source, otherSource, website, pixelValue, disabledEpc, article]);

    useEffect(() => {
        setVerifiedUrl('');
        (timeoutIsBlacklistedUrl) && clearTimeout(timeoutIsBlacklistedUrl);
        if (url) {
            timeoutIsBlacklistedUrl = setTimeout(() => {
                isBlacklistedUrl(url, site).then(() => {
                    setVerifiedUrl(url);
                }).catch(_ => {
                    // End user shouldn't know about blacklists and stuff
                    // We just display a generic error that will allow us to track it to the blacklist
                    setError('Article could not be found')
                });
            }, 200);
        }
    }, [url]);

    useEffect( () => {
        const constructCampaignName = () => {
            let parts = [prefix, country, getDeviceLetter(device), site, codeName];
            if (freetext) {
                parts.push(freetext);
            }
            if (additionalParameters) {
                parts.push(additionalParameters);
            }
            return parts.join('-');
        }

        const constructUrlTags = () => {
            const name = constructCampaignName();
            const kv = {
                utm_source: (source === 'other' ? otherSource : source) + '-' + prefix,
                utm_campaign: name,
            };
            if (source === 'facebook' && pixelValue.length && !disabledEpc) {
                return pixelValue.map(item => item.value).map(value => {
                    kv.pixel_value = value;
                    return Object.keys(kv).map(key => key + '=' + kv[key]).join('&')
                })
            }
            if (additionalParameters && isNativeSource(source)) {
                kv.additionalParameters = additionalParameters;
            }
            if (source === 'facebook' && disabledEpc) {
                kv.cot = `lc`
            }
            return Object.keys(kv).map(key => key + '=' + kv[key]).join('&')
        };
        setTags(constructUrlTags());
    }, [source, otherSource, prefix, country, device, site, codeName, freetext, pixelValue, additionalParameters, disabledEpc]);

    useEffect( () => {
        if (verifiedUrl) {
            if (verifiedUrl.includes('?')) {
                (source === 'facebook' && Array.isArray(tags) && !disabledEpc)
                    ? setFullUrl(tags.map(tag => `${verifiedUrl}&${tag}`))
                    : setFullUrl(`${verifiedUrl}&${tags}`)
            }
            else {
                (source === 'facebook' && Array.isArray(tags) && !disabledEpc)
                    ? setFullUrl(tags.map(tag => `${verifiedUrl}?${tag}`))
                    : setFullUrl(`${verifiedUrl}?${tags}`)
            }
        } else {
            setFullUrl('')
        }
    }, [verifiedUrl, tags]);

    function sanitizeUrl(url) {
        return url.toLowerCase().replace(/[^\w_-]/gi, '');
    }


    const copyToClipboard = (url) => {
        if (navigator && navigator.clipboard) {
            navigator.clipboard.writeText(url);
        }
        toast.info('Copied');
    }

    return (
        <div>
            <div className="container">
                <div className="geoplatform-wrapper">
                    <div className="geoplatform-item">
                        <div>
                            <label>Prefix</label>
                            <input type="text" name="prefix" value={prefix}
                                onChange={e => setPrefix(sanitizeUrl(e.target.value))} maxLength={2}/>
                        </div>
                        <div>
                            <label>Site</label>
                            <input type="text" name="site" value={site}
                                onChange={e => setSite(e.target.value.toLowerCase())} maxLength={3}/>
                        </div>
                        <div>
                            <label>Country code</label>
                            <input type="text" name="country" value={country}
                                onChange={e => setCountry(e.target.value.toLowerCase())} maxLength={2}/>
                        </div>
                        <div>
                            <label>Device</label>
                            <Select
                                clearable={false}
                                closeOnSelect={true}
                                multi={false}
                                onChange={setDevice}
                                options={DEVICE_LIST}
                                removeSelected={true}
                                rtl={false}
                                simpleValue
                                value={device}
                            />
                        </div>
                        <div>
                            <label>Traffic source</label>
                            <Select
                                clearable={false}
                                closeOnSelect={true}
                                multi={false}
                                onChange={setSource}
                                options={SOURCE_LIST}
                                removeSelected={true}
                                rtl={false}
                                simpleValue
                                value={source}
                            />
                        </div>
                        {source === 'other'
                            ? <div>
                                <label>Edit traffic source</label>
                                <input type="text" name="otherSource" value={otherSource}
                                    onChange={e => setOtherSource(sanitizeUrl(e.target.value))}/>
                            </div>
                            : null}
                        <div>
                            <label>Article code</label>
                            <Select
                                clearable={false}
                                closeOnSelect={true}
                                multi={false}
                                onChange={setArticle}
                                options={articlesOptionsList}
                                removeSelected={true}
                                rtl={false}
                                simpleValue
                                value={article}
                                isLoading={loading}
                            />
                        </div>
                        <div>
                            <label>Freetext</label>
                            <input type="text" name="freetext" value={freetext}
                                onChange={e => setFreetext(sanitizeUrl(e.target.value))} maxLength={20}/>
                        </div>
                        {isNativeSource(source) &&
                        <div>
                            <label>Additional parameters</label>
                            <input type="text" name="additionalParameters"
                                value={additionalParameters}
                                onChange={e => setAdditionalParameters(sanitizeUrl(e.target.value))}/>
                        </div>
                        }
                        {source === 'facebook' && 
                            <>
                                <div className="tooltip">
                                    <label>EPC value</label>
                                    <Select
                                        clearable={true}
                                        closeOnSelect={true}
                                        multi={true}
                                        onChange={selected => setPixelValue(selected)}
                                        options={pixelValuesList}
                                        removeSelected={true}
                                        rtl={false}
                                        value={disabledEpc ? 0 : pixelValue }
                                        disabled={disabledEpc}
                                    />
                                    <span className="select-tooltiptext">You can do multiple selection</span>
                                </div>
                                <div className="disable-epc tooltip">
                                    <label>
                                        <input
                                            name='disable-epc'
                                            type="checkbox"
                                            onChange={e => setDisabledEpc(e.target.checked)}
                                            checked={disabledEpc}
                                        />
                                        Link clicks
                                    </label>
                                    <span className="tooltiptext">If checked, no need to add EPC value</span>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
            {fullUrl && (source === 'facebook' && !disabledEpc ?
                <div>
                    {Array.isArray(fullUrl) ? fullUrl.map(item => 
                        <div key={item}> 
                            {item}
                            <button type="button" className="btn copy-to-clipboard"
                                    onClick={() => copyToClipboard(item)}>Copy to clipboard
                            </button>
                        </div>
                    ) : null}
                </div>
                :
                <div>
                    {fullUrl}
                    <button type="button" className="btn copy-to-clipboard"
                        onClick={() => copyToClipboard(fullUrl)}>Copy to clipboard
                    </button>
                </div>
            )}
            {error && (<div><label>{error}</label></div>)}
        </div>
    );
}

export default UrlCreator;
