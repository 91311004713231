const Nav = () => {
    return (
        <div className="header">
          <div className="logo">
            <img src="images/new_shinez_full_logo.png"/>
          </div>
        </div>
    );
}

export default Nav;
