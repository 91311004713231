export const ALLOWED_VERSIONS = ['shz', 'oct', 'bra', 'frs', 'wzm', 'apo'];

export const SITE_WIDGET_FILTER = {
    'nmi': ['mim'],
    'tup': ['mim']
};

export const DEVICE_LIST = [{
    label: 'Mobile',
    value: 'mobile'
}, {
    label: 'Tablet',
    value: 'tablet'
}, {
    label: 'Desktop',
    value: 'desktop'
}, {
    label: 'All',
    value: 'all'
}];

export const SOURCE_LIST = [{
    label: 'Facebook',
    value: 'facebook'
}, {
    label: 'Other',
    value: 'other'
}];
