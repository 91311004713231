async function checkStatus(res) {
  if (!res.ok) { // res.status >= 200 && res.status < 300
    throw new Error(await res.text());
  }
  return res;
}

export function getArticles() {
  const url = `${process.env.REACT_APP_API_URL}/api/articlesdict`;
  return fetch(url).then(checkStatus)
  .then(res => {
    return res.json()
  });
}

export function getWidgetConfig() {
  const url = `${process.env.REACT_APP_API_URL}/api/widgetconfig`;
  return fetch(url).then(checkStatus)
  .then(res => {
    return res.json()
  });
}

export function getWidgetPath(widgetId) {
  //console.log('called getWidgetPath');
  const url = `${process.env.REACT_APP_API_URL}/api/widget-paths/` + widgetId + "/";
  return fetch(url).then(checkStatus)
      .then(res => {
        return res.json()
      });
}

export function isBlacklistedUrl(url, site) {
  //console.log('called isBlacklistedUrl');
  return fetch(`${process.env.REACT_APP_API_URL}/api/site/url-blacklisted?site=${site}&url=${url}`).then(checkStatus).then( res => res.json());
}

export function generatePixelValues() {
  return Array.from({ length: 40 }, (_, i) => ({
    label: parseFloat(((i + 1) * 0.01).toFixed(2)),
    value: parseFloat(((i + 1) * 0.01).toFixed(2)),
  }));
}